.alert-box .jw-modal-body {
    width: 22%;
    margin: 0 auto !important;
}
.doc-box .jw-modal-body {
  width: 500px;
  height: 230px;
  margin: 0 auto !important;
  margin-top: 98px !important;
}
// .doc-box jw-modal .jw-modal .jw-modal-body {
//   top: 40%;
// }
.invalid-feedback {
    display: block !important;
}
* .form-group {
    margin-top: 10px;
  }
  
  .name-set {
    width: 90%;
  }
  
  .full-width {
    width: 100%;
  }
  
  .stepone.green {
    background: green;
  }
  
  .stepone.yallow {
    background: yellow !important;
  }
  
  .width-alignment {
    max-width: 90%;
    margin: 0 auto;
    // padding-right: 5%;
  }
  
  .Individual {
    width: 90%;
    padding: 20px;
    border: 1px solid #e8e8e8;
    box-shadow: 0 0 10px #e8e8e8;
    border-radius: 5px;
    margin: 0 auto;
  }
  
  div.d-inline {
    display: inline-flex !important;
  }
  
  .padding-unset {
    padding: unset;
  }
  
  .full-width {
    width: 100%
  }
  
  .float-right {
    float: right;
  }
  
  .float-left {
    float: left !important;
  }
  
  .center {
    text-align: center;
  }
  
  element.style {
    display: block;
  }
  
  .modal-open .modal {
    //   overflow-x: hidden;
    //   overflow-y: auto;
    //   position: absolute;
    //  clear: both;
  }
  
  .modal-body {
    padding: unset
  }
  
  .alertbtn {
    margin: 10px 5px;
  }
  
  // .btn-close {
  //   position: absolute;
  //   right: 0;
  //   padding: 20px;
  
  // }
  
  .green {
    background: green;
  }
  
  .yallow {
    background: #00b3a7;
  }
  
  .btnBack {
    margin-right: 10px;
  }

  
.main-width {
    width: 90%;
    margin: 0 auto;
  }
  
  .ng-autocomplete {
    width: auto !important;
  
  }
  
  .input-container input {
    background: #eeeeee !important;
  }
  
  .cdk-overlay-pane {
    z-index: 999999;
  }
  
  // button {
  //   border: none;
  //   padding: 5px;
  // }
  
  span.input-group-btn {
    background: #EEEEEE;
  }
  
  .doc-view-scs {
    .jw-modal-body {
      width: 22%;
      margin: 0 auto !important;
      text-align: center;
      position: relative;
      top: 16%;
    }
  
    .close-x {
      text-align: end;
    }
  }
  
  .tab-margin {
    margin: 10px 0;
  }
  
  .doc-merge-success .jw-modal-body {
    width: 20%;
    margin: 0 auto !important;
  }
  
  .fontweight {
    font-weight: bold;
  }
  .doc-iframe-scs .jw-modal .jw-modal-body{
    top: 10%;
    width: 50%;
    margin: 0 auto;
    height: 80%;
    .doc-iframe-scs-body{
      height: 90%;
    }
  }
  .safeurl{
    height:500px;
    width:100%;
  }
  .common-alert{
    .jw-modal{
      width: 30%;
      margin: 0 auto;
    }
    .center{
      margin: 0 auto;
      text-align: center;
    }
  }
  .row.side-nav-custom {
    margin-top:70px;
  }
  
  header.header {
    position:fixed;
  }
  
  .margin-div {
    margin-bottom: 20px;
  }
  .b-radius{
    border-radius: 5px !important;
  }
  .multicheck.form-control.textbox {
    width: 100%;
  }
  .textbox-bg{
    background:#EEEEEE;
  }
  i.fa {
    cursor:pointer;
  }
  .margin-top{
    margin-top: 30px;
  }
  .margin-bottom{
    margin-bottom: 30px;
  }
  .close-x{
    padding: 10px;
  }